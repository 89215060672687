import React, { useEffect, useMemo, useState } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { makeStyles } from "@mui/styles";
import EcnButton from "../../StyledComponents/EcnButton";
import { addCard } from "../../../redux/slices/Payment/addCardSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import countryList from "react-select-country-list";
import { motion } from "framer-motion";
import EcnInput from "../../StyledComponents/EcnInput";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { updateBillingAccount } from "../../../redux/slices/Licenses/updateBillingAccountSlice";
import { recruitRedirect } from "../../../helpers/TempData";

const API_GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE || "";
const styles = makeStyles(() => ({
  cardContainer: {
    width: "590px",
    borderRadius: "8px",
    padding: "25px",
    border: "1px solid rgba(33, 33, 33, 0.2)",
    marginTop: "0",
    background: "#FFF",
    margin: "auto",
    paddingBottom: "20px",
    maxHeight: "500px",
    overflow: "scroll",
  },
  cardWrapper: {
    maxWidth: "540px",
  },
  head: {
    fontWeight: "600",
    color: "#212121",
    marginBottom: "10px",
  },
  field: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "15px",
  },
  fieldText: {
    width: "30%",
    minWidth: "250px",
  },
  heading: {
    fontSize: "14px",
    fontWeight: "400",
    color: "#212121",
    lineHeight: "24px",
    marginBottom: "-5px"
  },
  fieldContainer: {
    border: "1px solid rgba(33, 33, 33, 0.2)",
    borderRadius: "8px",
    padding: "12px 16px",
    width: "100%",
    backgroundColor: "white",
  },
  btnWrapper: {
    marginTop: "38px",
    textAlign: "center",

  },
  submitBtn: {
    fontSize: "16px",
    borderRadius: "30px !important",
    padding: "12px 43px !important",
    fontWeight: "500 !important",
    width: "30%",
    margin: "auto"
  },
  fieldWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "25px",
  },
  selectInput: {
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "8px 16px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "400 !important",

    borderRadius: "8px",
    background:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 9l6 6l6-6'/%3E%3C/svg%3E\") no-repeat right 16px center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundColor: "#fff",
    width: "100%",

    "&:hover": {
      borderColor: "#000",
    },
    "&:focus": {
      borderColor: "#0034BB",
    },
    "& option": {
      color: "red",
    },
  },
  selectInput1: {
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.2)",
    padding: "8px 16px",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "400 !important",

    borderRadius: "8px",
    background:
      "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24'%3E%3Cpath fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 9l6 6l6-6'/%3E%3C/svg%3E\") no-repeat right 16px center",
    "-moz-appearance": "none",
    "-webkit-appearance": "none",
    appearance: "none",
    backgroundColor: "#fff",
    width: "250px",

    "&:hover": {
      borderColor: "#000",
    },
    "&:focus": {
      borderColor: "#0034BB",
    },
    "& option": {
      color: "red",
    },
  },
  label: {
    fontSize: "14px",
    color: "#212121",
    marginBottom: "-5px",
  },
}));

interface CheckoutFormProps {
  isDefault?: boolean;
}

const CheckoutForm = ({ isDefault }: CheckoutFormProps) => {
  const classes = styles();
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useAppDispatch();

  const { addCardData, loading } = useAppSelector((state) => state.addCard);
  const { userInfo, error: userInfoError } = useAppSelector(
    (state) => state.userDetails,
  );
  const { updateBill, error } = useAppSelector(
    (state) => state.updateBillingAccount
  );

  const [showCardholderName, setShowCardholderName] = useState(false);

  const [cardBrand, setCardBrand] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<any>()
  const [country, setCountry] = useState<any>("")


  const [billingData, setBillingData] = useState<any>({
    billing_name: userInfo?.first_name + " " + userInfo?.last_name,
    billing_mobile: "",
    billing_email: userInfo?.email,
    billing_company: "",
    organisation_tax_id: "",
    organisation_name: "",
    legal_details: "",
    billing_address: {
      street_address: "",
      country: "",
      state: "",
      city: "22",
      zipcode: "",
    },
  });

  useEffect(() => {
    if (!stripe) {
      return;
    }
  }, [stripe]);

  useEffect(() => {
    if (userInfo) {
      setBillingData({
        ...billingData,
        billing_name: userInfo?.first_name + " " + userInfo?.last_name,
        billing_email: userInfo?.email,
      })
    }
  }, [userInfo]);


  useEffect(() => {
    if (addCardData) {
      window.location.href = `${recruitRedirect(API_GATEWAY_BASE)}dashboard`;
    }
  }, [addCardData]);

  useEffect(() => {
    if (updateBill) {
      const executeAddCardHandler = async () => {
        await addCardHandler();
      };
      executeAddCardHandler();
    }
  }, [updateBill]);

  useEffect(() => {
    checkFieldsValidity();
  }, [elements]);
  // Function to check all fields' validity
  const checkFieldsValidity = () => {
    const cardNumberElement = elements?.getElement(CardNumberElement);
    const expiryElement = elements?.getElement(CardExpiryElement);
    const cvcElement = elements?.getElement(CardCvcElement);

    // State to track the completeness of each field
    const fieldStatus = {
      cardNumberComplete: false,
      expiryComplete: false,
      cvcComplete: false,
    };

    const updateShowCardholderName = () => {
      if (fieldStatus.cardNumberComplete && fieldStatus.expiryComplete && fieldStatus.cvcComplete) {
        setShowCardholderName(true);

      } else {
        setShowCardholderName(false);
      }
    };

    if (cardNumberElement && expiryElement && cvcElement) {
      cardNumberElement.on("change", (event) => {
        fieldStatus.cardNumberComplete = event.complete;
        setCardBrand(event.brand || "");
        updateShowCardholderName();
      });

      expiryElement.on("change", (event) => {
        fieldStatus.expiryComplete = event.complete;
        updateShowCardholderName();
      });

      cvcElement.on("change", (event) => {
        fieldStatus.cvcComplete = event.complete;
        updateShowCardholderName();
      });
    }
  };



  const addCardHandler = async () => {


    if (!stripe || !elements) return;

    setIsLoading(true);
    const cardNumberElement = elements.getElement(CardNumberElement);

    if (cardNumberElement) {
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,

      });

      setResult(result)
      if (result.paymentMethod) {
        dispatch(
          addCard({
            payment_method_id: result.paymentMethod.id,
            set_default_payment_method: isDefault || false,
            country
          })
        );
      } else if (result.error) {
        setMessage(result.error.message || "An error occurred");
      }
      setIsLoading(false);
    }
  };



  console.log("result", result)
  const saveBillingDataHandler = () => {
    dispatch(
      updateBillingAccount({
        ...billingData,
        billing_name: userInfo.first_name + " " + userInfo.last_name,
      })
    );
  };

  const cardElementOptions = {
    showIcon: true,
    style: {
      base: {
        fontFamily: "Poppins, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        backgroundColor: "white",
        "::placeholder": {
          color: "rgba(33, 33, 33, 0.6)",
        },
      },
      invalid: {
        iconColor: "#ba0000",
        color: "#ba0000",
      },
    },
  };

  const addressHandler = (value: string, name: string) => {
    if (name === "country") {
      setCountry(value)
    }
    setBillingData({
      ...billingData,
      billing_address: {
        ...billingData.billing_address,
        [name]: value,
      },
    });
  };

  const billingDataHandler = (e: any) => {
    setBillingData({
      ...billingData,
      organisation_name: e.target.value,
    });
  };

  console.log("billingData", billingData)

  return (
    <div id="payment-form" >
      <div className={classes.cardContainer}>
        <p className={classes.head}>Enter Card Details</p>

        <div className={classes.cardWrapper}>
          <div className={classes.field}>
            <div className={classes.fieldText}>
              <p className={classes.heading}>Card number</p>
            </div>
            <div className={classes.fieldContainer}>
              <CardNumberElement options={cardElementOptions} />
            </div>
          </div>

          <div className={classes.fieldWrapper}>
            <div className={classes.field}>
              <div className={classes.fieldText}>
                <p className={classes.heading}>Expiry date</p>
              </div>
              <div className={classes.fieldContainer}>
                <CardExpiryElement options={cardElementOptions} />
              </div>
            </div>

            <div className={classes.field}>
              <div className={classes.fieldText}>
                <p className={classes.heading}>CVC</p>
              </div>
              <div className={classes.fieldContainer}>
                <CardCvcElement options={cardElementOptions} />
              </div>
            </div>
          </div>

          {showCardholderName && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <h1 style={{ fontSize: "16px", fontWeight: "600", color: "#212121", margin: "15px 0px" }}>Enter billing address*</h1>
              <div className={classes.field}>
                <div className={classes.fieldText}>
                  <p className={classes.heading}>Organisation Name*</p>
                </div>

                <EcnInput
                  className="ecn-input"
                  color="info"
                  placeholder="Enter cardholder name"
                  value={billingData.organisation_name}
                  onChange={billingDataHandler}
                  InputProps={{
                    style: { fontWeight: 400 }, // Reduce font weight for the inner input
                  }}
                />


              </div>
              <div className={classes.field}>
                <div className={classes.fieldText}>
                  <p className={classes.heading}>Country*</p>
                </div>

                <CountryDropdown
                  classes={classes.selectInput}
                  name="country"
                  value={billingData.billing_address.country}
                  onChange={(val) => addressHandler(val, "country")}
                />

              </div>
              <div className={classes.field}>
                <div className={classes.fieldText}>
                  <p className={classes.heading}>Address*</p>
                </div>

                <EcnInput
                  className="ecn-input"
                  color="info"
                  placeholder="Enter address"
                  value={billingData.billing_address.street_address}
                  onChange={(e) => addressHandler(e.target.value, "street_address")}
                  InputProps={{
                    style: { fontWeight: 400 }, // Reduce font weight for the inner input
                  }}
                />

              </div>
              <div className={classes.fieldWrapper}>
                <div className={classes.field}>
                  <label htmlFor="" className={classes.label}>
                    Zip Code*
                  </label>
                  <EcnInput
                    className="ecn-input"
                    color="info"
                    placeholder="Enter Zip Code"
                    value={billingData.billing_address.zipcode}
                    onChange={(e) => addressHandler(e.target.value, "zipcode")}
                    style={{ width: "250px" }}
                    InputProps={{
                      style: { fontWeight: 400 }, // Reduce font weight for the inner input
                    }}
                  />
                </div>
                <div className={classes.field}>
                  <label htmlFor="" className={classes.label}>
                    State*
                  </label>
                  <RegionDropdown
                    classes={classes.selectInput1}
                    country={billingData.billing_address.country}
                    value={billingData.billing_address.state}
                    onChange={(val) => addressHandler(val, "state")}

                  />
                </div>
              </div>


            </motion.div>

          )}
        </div>

      </div>

      <div className={classes.btnWrapper}>
        <EcnButton
          color="info"
          variant="contained"
          className={classes.submitBtn}
          onClick={saveBillingDataHandler}
          disabled={isLoading || loading || !stripe || !elements || billingData.organisation_name === "" || billingData?.billing_address?.street_address === "" || billingData?.billing_address?.country === "" || billingData?.billing_address?.state === "" || billingData?.billing_address?.zipcode === ""}
        >
          <span id="button-text">Add Card</span>
        </EcnButton>
      </div>
      {message && <div id="payment-message">{message}</div>}
    </div>
  );
};

export default CheckoutForm;
